$(function() {
  const offset = 20;
  const drawer = $('#drawer');
  const width = drawer.width();
  let current = drawer.offset().left -width -offset;
  drawer.css({left: current});
  
  $('.sidenav.close, #backdrop').on('click', function() {
    const newPos = {left: -width -offset}
    drawer.css({left: current}).animate(newPos);
    current = newPos;
    $('#backdrop').css({display: 'none'});

  });

  $('.header .menu').on('click', function() {
    drawer.css({left: current}).animate({left: 0});    
    current = 0;

    $('#backdrop').css({display: 'block'}).css({opacity: '0'}).animate({opacity: '1'});
  });


  // animate header when hover
  $('.navbar .nav-item').on('mouseenter', async function() {
    await $('.navbar .nav-item .dropdown').removeClass('show');
    
    if(this.children.length < 2) {
      collapse();
    }

    $(this).children().each(function(item) {
      
      if (item > 0) {
        //extend header height
        if(window.innerWidth > 1279) {

          $('.header').animate({height: 140}, 300);
          $(this).addClass('show')
          $('.dropdown').addClass('lg:border-t lg:border-midgrey1')
        }
      } 
    });
  });

  // animate header when hover
  $('.header').on('mouseleave', collapse);

  $('.dropdown-toggle').on('click', function() {
    const display = $(this).parent().siblings().hasClass('show');
    !display ? $(this).parent().siblings().addClass('show') : $(this).parent().siblings().removeClass('show');
  })

  
  let initHeight = $('.header').height();


  $(window).on('resize', () => {
    
    if(window.innerWidth < 1280) {
      initHeight = 48;
    } else {

      initHeight = 81;
    }
    $('.header').animate({height: initHeight}, 300);

  });
  
  function collapse() {

    $('.navbar .nav-item .dropdown').removeClass('show');
    $('.nav').removeClass('lg:border-b lg:border-primary')
    $('.header').animate({height: initHeight}, 300);
  }

});

function openTab(event, tabId) {
  console.log(event, tabId);
  // hide all tabs
  document.querySelectorAll('.tab-content').forEach(tab => tab.classList.remove('active'));
  
  // remove primary class to tab
  document.querySelectorAll('.tab').forEach(btn => 
    btn.classList.remove('active'));
  
 // show selected tab content
  document.getElementById(tabId).classList.add('active');
  
  // add primary class to clicked btn
  event.currentTarget.classList.add('active');
}